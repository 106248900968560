// import code for every page

import '@/helpers/cookieConsent';
import '@/helpers/scrollOut';
import '@/helpers/animations/AnimateOnScroll';
import '@/helpers/smoothScroll';
import '@/helpers/onScreenKeyboardDetector';
import '@/helpers/lightboxImages';
import { loadSvg } from '@/core/utils';

// import code by section existence
// section should have data-js-import="name" attribute

const sections = [
  'header-navigation',
  'dc-locations',
  'vps-pricing',
  'free-vps-calc',
  'free-vps-request',
  'free-vps-app',
  'advantages',
  'our-team',
  'testimonials',
  'faq',
  'solution-first-step',
  'solution-second-step',
  'solution-third-step',
  'solution-demo',
  'integration-types',
  'terms',
  'kb',
  'broker-latency-hero',
  'check-latency',
  'contact-hero',
  'how-much-earn',
  'affiliates-signup',
  'affiliates-wizard',
  'pepperstone-sponsored-hero',
  'monthly-promo-ribbon',
  'seasonal-promo-ribbon',
  'xmas-promo-ribbon',
  'exit-pop-up',
  'free-trial-promo-ribbon',
  'control-in-hands',
  'ultimate-server',
  'server-room',
  'algo-trader-robot',
  'more-reasons',
  'vps-crucial',
  'ea-first-step',
  'ea-second-step',
  'ea-fourth-step',
  'kb-sidebar',
  'configuration-slider',
  'perks-tabs',
  'influencers-hero',
  'ifexpo-page',
  'animate-number-counter',
  'about-contact-form',
  'phone-testimonials',
  'background-animated-mouseover',
  'vps-importance',
  'number-spin-up',
  'vertical-scroll-logos',
  'background-particles',
  'quiz'
];


sections
  .filter((name) => document.querySelector(`section[data-js-import="${name}"]`))
  .forEach((name) => {
    import(
      // /* webpackChunkName: "forexvpsChunk" */
      `@/blocks/${name}`
    );
  });

// import code by smarty page variables

if (fvpsApp.useSocialProof) {
  import('@/components/social-proof');
}

// enable css animations

setTimeout(function () {
  document.body.classList.remove('preload');
}, 100);

/* eslint-disable */
window.gaAddToCart = (productCategory, productName, productID, productPrice, productCurrency) => {
  if (
      typeof productCategory !== 'undefined' && productCategory !== null && productCategory !== ''
      && typeof productName !== 'undefined' && productName !== null && productName !== ''
      && typeof productID !== 'undefined' && productID !== null && /^\d+$/.test(productID)
      && typeof productPrice !== 'undefined' && productPrice !== null && productPrice !== ''
      && typeof productCurrency !== 'undefined' && productCurrency !== null && productCurrency.length === 3
  ) {
    productPrice = productPrice.replace(/[^0-9.,]/g, "");
    window.dataLayer.push({
      ecommerce: {
        currency: productCurrency,
        value: productPrice,
        items: [
          {
            item_id: productID,
            item_name: productName,
            item_brand: productCategory,
            price: productPrice
          },
        ],
      },
      event: 'add_to_cart',
    });

    if (typeof fbq !== 'undefined') {
      fbq('track', 'AddToCart', {
        content_name: productName,
        content_category: productCategory,
        content_ids: [productID],
        content_type: 'product',
        value: productPrice,
        currency: productCurrency
      });
    }
  }
};
/* eslint-enable */

document.addEventListener('DOMContentLoaded', () => {
  loadSvg(fvpsApp.svgSpriteUrl);
});
